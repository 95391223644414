import { useCallback, type ReactNode } from "react";
import Button, { type ButtonProps } from "./ui/Button";
import { $isShareOpen } from "stores/share";
import { cn } from "@utils/cn";

interface Props extends Partial<ButtonProps> {
  url: string;
  icon?: ReactNode;
}

export default function ShareButton({ url, icon, children, className, ...props }: Props) {
  const onClick = useCallback(() => {
    if (!navigator.share) {
      $isShareOpen.set(true);
      return;
    }
    navigator.share({
      url,
      title: "Reviuer - Building Code Compliance",
      text: "reviuer.com is trying to streamline the building code compliance review process",
    });
  }, []);

  return (
    <Button
      style="outline"
      size="xs"
      block
      className={cn("flex gap-1.5 items-center justify-center", className)}
      onClick={onClick}
      {...props}>
      {icon}
      {children}
    </Button>
  );
}
